import React from "react";
import Image from "../image/image.js";

import "./style.scss";

const InfoCard = ({ title, body, advantages, filename, alt, index }) => {
  return (
    <div className="infoCard">
      {index % 2 !== 0 ? (
        <div className="container">
          <div className="columns">
            <div className="column is-5">
              <div className="infoCardText">
                <span>{title}</span>
                <br />
                <br />
                {body}
                {advantages.map((i, index) => (
                  <div className="advantages" key={index}>
                    <div className={`${i.sign}`} />
                    &nbsp;&nbsp;{i.text}
                    <br />
                  </div>
                ))}
              </div>
              <br />
            </div>
            <div className="column is-7">
              <div className="infoCardImageContainer">
                <div className="infoCardImage floatA">
                  <Image filename={filename} alt={alt} />
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="container">
          <div className="columns invert">
            <div className="column is-7">
              <div className="infoCardImageContainer left">
                <div className="infoCardImage floatB">
                  <Image filename={filename} alt={alt} />
                </div>
              </div>
            </div>
            <div className="column  is-5">
              <div className="infoCardText">
                <span>{title}</span>
                <br />
                <br />
                {body}
                <br />
                <br />
                {advantages.map((i, index) => (
                  <div className="advantages" key={index}>
                    <div className={`${i.sign}`} />
                    &nbsp;&nbsp;{i.text}
                    <br />
                  </div>
                ))}
              </div>
              <br />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default InfoCard;
