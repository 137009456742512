import React from "react";
import Header from "../components/header/header";
import InfoCard from "../components/infoCard/infoCard.js";

const INFOCARDS = [
  {
    title:
      "Grazie al checkIN online facilita l’operatività del receptionist e consente al tuo cliente di registrarsi ovunque esso si trovi!",
    body:
      "Il booking engine è un generatore automatico di prenotazioni, grazie al quale potrai risparmiare sui costi delle commissioni avendo la possibilità di gestire disponibilità, tariffe ed offerte",
    filename: "ota.png",
    alt: "Spiral",
    to: "/marketing",
    advantages: [
      { sign: "plus", text: "Prenotazioni dirette" },
      { sign: "minus", text: "Commissioni" },
      { sign: "plus", text: "Guadagni" },
    ],
  },
  {
    title: "Nessun rischio di overbooking",
    body: "Disponibilità e prezzi allineati in tempo reale",
    filename: "booking.png",
    alt: "Spiral",
    to: "/marketing",
    advantages: [],
  },
  {
    title: "CheckIN online",
    body:
      "La funzione del checkIN online, rende la prenotazione più semplice per il tuo cliente, che in maniera autonoma può inviare documenti e tutte le informazioni per l’acquisto del soggiorno, facilitando il lavoro del receptionist nella gestione della prenotazione",
    filename: "checkinfinale.png",
    alt: "Spiral",
    to: "/marketing",
    advantages: [],
  },
  {
    title: "Statistiche e Revenue Management",
    body:
      "Con l’integrazione di Book@Me al tuo gestionale potrai monitorare e confrontare i dati (negli anni e per periodi) riguardo alle prenotazioni. Le statistiche ti aiuteranno a prendere le giuste decisioni per una strategia adeguata alla tua struttura",
    filename: "revenue.png",
    alt: "Spiral",
    to: "/marketing",
    advantages: [],
  },
  {
    title: "Responsive Design",
    body:
      "Grafica ottimizzata per tutti i dispositivi mobili e desktop sia lato operativo sia end user",
    filename: "smartipaf.png",
    alt: "Spiral",
    to: "/marketing",
    advantages: [],
  },
  {
    title: "Customer Experience",
    body:
      "Veloce e semplice. Il cliente impiegherà solo 3 minuti per confermare la sua prenotazione!",
    filename: "cuffie.png",
    alt: "Spiral",
    to: "/marketing",
    advantages: [],
  },
  {
    title: "Localizzazione",
    body:
      "Italiano, inglese, tedesco, francese, russo, spagnolo, portoghese Book@Me parlerà con il tuo cliente in base alla lingua impostata sul browser ",
    filename: "language.png",
    alt: "Spiral",
    to: "/marketing",
    advantages: [],
  },
  {
    title: "Tariffe e prezzi",
    body:
      "Gestione dinamica di prezzi e tariffe Possibilità di configurare diversi listini (standard, non rimborsabile, advance booking) e per ognuno di essi avere diverse fasce di prezzo. Ogni giorno potrai scegliere quale fascia applicare. I prezzi possono essere impostati anche manualmente per singoli giorni (Es: ponti di festività, giorni particolari, etc…) con estrema semplicità",
    filename: "money.png",
    alt: "Spiral",
    to: "/marketing",
    advantages: [],
  },
  {
    title: "API",
    body:
      "Collegamento tramite API semplice e veloce. Lo sviluppo di API dedicate, permette la facile integrazione con qualsiasi software gestionale, facilitandone ancor di più l’utilizzo e l’efficienza",
    filename: "gear.png",
    alt: "Spiral",
    to: "/marketing",
    advantages: [],
  },
  {
    title: "Campagne promozionali",
    body:
      "Gestione di campagne promozionali con generazione di codici riservati da applicare a uno o più listini con validità temporale e scontistiche riservate. Inserendo il codice nell’apposito input il cliente vedrà subito il risparmio. Questi codici possono essere inviati tramite mail o pubblicati sui social per fidelizzare maggiormente i clienti",
    filename: "megaphone.png",
    alt: "Spiral",
    to: "/marketing",
    advantages: [],
  },
];

const IndexPage = () => {
  return (
    <>
      <Header
        title="Home"
        metaDescription="Book@Me è un booking engine con channel manager integrato in cloud che ti consente di ricevere prenotazioni dirette senza commissioni."
      />
      <div className="columns mt-6">
        <div className="column is-half-widescreen is-offset-one-quarter-widescreen">
          <p>
            Book@Me è un <b>booking engine con channel manager integrato in cloud </b>
            che ti consente di ricevere prenotazioni dirette senza commissioni,
            <b> abbattendo costi per il tuo business</b>, e di gestire tutti i tuoi
            canali web grazie al channel manager, <b>evitando così il rischio di
            overbooking</b>. Grazie al <b>checkIN online</b> facilita l’operatività del
            receptionist e consente al tuo cliente di registrarsi ovunque esso
            si trovi!
          </p>
        </div>
      </div>
      {INFOCARDS.map((i, index) => (
        <InfoCard {...i} index={index} key={index} />
      ))}
    </>
  );
};

export default IndexPage;
